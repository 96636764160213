import React from 'react'
import { Typography, Box, Grid } from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email'
import FacebookIcon from '@material-ui/icons/Facebook'

const styles = {
	redbox: {
		backgroundColor: '#f54b4b',
		minHeight: '100px',
		marginTop: '0px',
		color: '#FFF',
		width: 'calc(100% + 16px)',
		margin: '0 -8px',
	},
	circle: {
		height: '100px',
		width: '100px',
		backgroundColor: '#fff',
		color: '#f54b4b',
		borderRadius: '50%',
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		margin: 'auto',
	},
	footerItem: { textAlign: 'center', paddingLeft: '10px', paddingRight: '10px' },
}

const Footer = () => {
	return (
		<>
			<Box>
				<Grid container spacing={4} justify='center' alignItems='center' style={styles.redbox}>
					<Grid item xs={12} md={4} style={styles.footerItem}>
						<Typography variant='h5'>Ota yhteyttä!</Typography>
					</Grid>
					<Grid item xs={12} md={4} style={styles.footerItem}>
						<a href='mailto:eila.heinonen@luottamus.joensuu.fi'>
							<Typography variant='h5' style={{ color: '#fff' }}>
								<EmailIcon style={{ paddingBottom: '0px' }} /> eila.heinonen@luottamus.joensuu.fi
							</Typography>
						</a>
					</Grid>
					<Grid item xs={12} md={4} style={styles.footerItem}>
						<a href='https://www.facebook.com/eila.heinonen.1'>
							<Typography variant='h5' style={{ color: '#fff' }}>
								<FacebookIcon /> Eila Heinonen
							</Typography>
						</a>
					</Grid>
					{/*
					<Grid item xs={12} md={12} style={styles.footerItem}>
						<div style={styles.circle}>
							<Typography variant='h2'>421</Typography>
						</div>
					</Grid>*/}
				</Grid>
			</Box>
		</>
	)
}
export default Footer
