import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
//import Head from './components/Head'
//import Game from './components/Game'
import Home from './components/Home'
import Vaaliteemat from './components/Vaaliteemat'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import { createMuiTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles'
import './App.css'
import KukaOlen from './components/KukaOlen'
import Kirjoituksia from './components/Kirjoituksia'

let theme = createMuiTheme({
	typography: {
		fontFamily: 'Barlow Semi Condensed',
		h1: {
			fontFamily: 'Barlow Semi Condensed',
			fontWeight: '800',
			textTransform: 'uppercase',
		},
		h2: {
			fontFamily: 'Barlow Semi Condensed',
			fontWeight: '600',
			textTransform: 'uppercase',
		},
		h3: {
			fontFamily: 'Barlow Semi Condensed',
			fontSize: '2rem',
			fontWeight: '400',
			color: '#be0a37',
		},
		h4: {
			color: '#fff',
		},
		body1: {
			fontFamily: 'Barlow',
			fontWeight: '400',
		},
		body2: {
			fontFamily: 'Roboto',
		},
	},
})

theme = responsiveFontSizes(theme)

const App = () => {
	return (
		<>
			<Router>
				<ThemeProvider theme={theme}>
					<Navbar />

					<Switch>
						<Route exact path='/'>
							<Home />
						</Route>
						<Route path='/vaaliteemat'>
							<Vaaliteemat />
						</Route>
						<Route path='/kirjoituksia'>
							<Kirjoituksia />
						</Route>
						<Route path='/kukaolen'>
							<KukaOlen />
						</Route>
					</Switch>
					<Footer />
				</ThemeProvider>
			</Router>
		</>
	)
}
export default App
