import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import Kokokuva from '../images/DSC_4104_Eila_Heinonen.jpg'

const KukaOlen = () => {
	const styles = {
		redbox: {
			backgroundColor: '#f54b4b',
			minHeight: '200px',
			paddingLeft: '10%',
			paddingRight: '10%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			color: '#fff',
		},
		imgBox: {
			backgroundImage: `url(${Kokokuva})`,
			backgroundSize: 'cover',
			backgroundPosition: 'top',
			backgroundRepeat: 'no-repeat',
			minHeight: '400px',
			//backgroundAttachment: 'fixed',
		},
		textbox: {
			paddingLeft: '10%',
			paddingRight: '10%',
			paddingTop: '40px',
			paddingBottom: '40px',
			color: '#28282d',
		},
		listbox: {
			fontFamily: 'Barlow',
			fontWeight: '400',
			paddingLeft: '10%',
			paddingRight: '10%',
			paddingTop: '40px',
			paddingBottom: '40px',
			color: '#28282d',
		},
		pinkbox: {
			backgroundColor: '#f5c8c3',
			paddingLeft: '10%',
			paddingRight: '10%',
			minHeight: '150px',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
		},
		redTextbox: {
			paddingLeft: '10%',
			paddingRight: '10%',
			paddingTop: '40px',
			paddingBottom: '40px',
			backgroundColor: '#be0a37',
			color: '#fff',
		},
	}
	return (
		<Grid container style={{ width: 'calc(100% + 16px)' }}>
			<Grid item xs={12} style={styles.redbox}>
				<Typography variant='h1'>Kuka olen?</Typography>
			</Grid>
			<Grid item xs={12} md={7}>
				<Typography variant='body1' style={styles.textbox}>
					Olen 66-vuotias mielenterveyshoitaja, sosiaaliohjaaja. Kotoisin olen Itä-Uudeltamaalta pienestä Pukkilan kunnasta ja Liljan likkoja. Pohjoiskarjalainen olen ollut vuodesta 1977 tullessani töihin Kontiolahteen silloiseen Kuurnan sairaalaan. Joensuuhun muutin v 1986. Sain tehdä
					pitkän ja antoisan työuran Pohjois-Karjalan sairaanhoito- ja sosiaalipalvelujen ky:ssä. Eläkkeelle jäin silloisen aikuispsykiatrian tehoavon kotiinvietävistä palveluista, kuntoutustyöryhmästä. Eläkkeelle siirtyminen tapahtui Siun soten muutosvaiheen aikana. Olen ollut mukana sen
					perustamiseen liittyvissä valmisteluryhmissä sekä Siun soten valtuutettuna ja ensimmäisen valtuuston puheenjohtajana.
					{/* Viisi vuotta sitten pääsin
					ammattialakohtaiselle eläkkeelle silloisen Aikuispsykiatrian Tehoavon kotiinvietävistä palveluista,
					kuntoutustyöryhmästä. */}
					<br></br>
					<br></br>
					Kotimme on kaupungin maaseutumaisemissa Kulhontiellä vanhan ja uuden Multimäen asuinalueen välimaastossa. Perheeseeni kuuluvat maaseutuyrittäjänä toimiva puoliso Asko ja jo aikuiset lapset Kaisa ja Matti. Lastenlapsia meillä on viisi, jotka ovat iältään 8, 10, 12, 16, 19 v.
					Minulla on myös mahdollisuus olla osallisena neljän sukupolven ketjussa tämän päivän arjessa. Joten voin nähdä tällä hetkellä varsin lähellä ja laaja-alaisesti niin ikääntymisen tuomat, lapsiperheen arjen kuin toisaalta myös aikuistuvien nuorten koulutukseen ja työelämään
					liittyviä arjen haasteita. Vapaa-aikana ulkoilen, nautin luonnon ja taiteen pienistä, isoista asioista.
				</Typography>
			</Grid>
			<Grid item xs={12} md={5} style={styles.imgBox}></Grid>
			<Grid item xs={12} style={styles.pinkbox}>
				<Typography variant='h3'>Nykyisiä luottamustehtäviä</Typography>
			</Grid>
			<Grid item xs={12} style={styles.listbox}>
				<ul>
					<li>Kaupunginvaltuutettu ja kaupunginhallituksen jäsen, Sdp-valtuustoryhmän vetäjä</li>
					<li>Maakuntavaltuutettu sekä sdp-valtuustoryhmän vetäjä</li>
					<li>Joensuun evlut seurakuntayhtymän kirkkovaltuutettu ja Pielisensuun srk:n seurakuntaneuvoston jäsen</li>
					<li>Kuuloliitto ry:n hallituksen jäsen sekä Itä-Suomen piirin puheenjohtaja </li>
					<li>Joensuun Seudun Kuulo ry:n puheenjohtaja </li>
					<li>Pohjois-Karjalan Osuuspankin edustajiston jäsen </li>
					<li>SDP-Joensuun kunnallisjärjestön hallituksen jäsen sekä Joensuun Työväenyhdistyksen johtokunnan jäsen</li>
				</ul>
			</Grid>
			<Grid item xs={12} md={6}>
				<Typography variant='body1' style={styles.textbox}>
					<span style={{ color: '#be0a37', fontWeight: 800 }}>Kokonaisnäkemystä lisäävät </span>vuosien varrella olleet mahdollisuudet osallistua ja olla eri kunnallisissa lautakunnissa, käräjäoikeuden lautamiehenä, eri AY-tehtävissä MKL/KTV/JHL, jne. Monipuolista kokemusta olen saanut
					myös työni kautta. Toimiminen lähellä ihmisen arkea monenlaisissa tilanteissa sekä omakohtainen kokemus kuulo- ja puhevammaisuudesta ovat antaneet minulle mahdollisuuden nähdä elämää erilaisista lähtökohdista käsin.
				</Typography>
			</Grid>
			<Grid item xs={12} md={6} style={styles.redTextbox}>
				<Typography variant='body1'>
					Uskon, että kokemustaustani antaa vahvaa pohjaa hyvinvointialueen eri toimijoiden kanssa tehtävään yhteistyöhön sekä päätöksentekoon. Pidetään huolta tarpeenmukaisten palvelujen saatavuudesta, oikea-aikaisuudesta ja saavutettavuudesta, iästä, asuinpaikasta tai elämäntilanteesta
					riippumatta.
					{/* Uskon, että näistä kokemuksista on edelleenkin hyötyä kunnallisessa päätöksenteossa. Yhteistyössä ja Sinua kuunnellen. <br></br>
					<br></br>Pidetään huolta kunnasta. Kunta pitää huolta meistä. */}
					<br></br>
				</Typography>
				<Typography variant='h3' style={{ color: '#fff' }}>
					Tavoitteena hyvinvoiva pohjoiskarjalainen.
					{/* Inhimillisen ja oikeudenmukaisen Joensuun puolesta. */}
				</Typography>
				<Typography>
					<br></br>Pidetään yhteyttä!
				</Typography>
				<a href='mailto:eila.heinonen@luottamus.joensuu.fi' class='mail-link'>
					eila.heinonen@luottamus.joensuu.fi
				</a>
			</Grid>
		</Grid>
	)
}

export default KukaOlen
