import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'

const NavMenu = () => {
	const [anchorEl, setAnchorEl] = useState(null)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	return (
		<div>
			<IconButton
				aria-controls='simple-menu'
				aria-haspopup='true'
				color='inherit'
				edge='start'
				onClick={handleClick}
			>
				<MenuIcon style={{ color: '#be0a37' }} />
			</IconButton>
			<Menu id='nav-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
				<Link to='/'>
					<MenuItem onClick={handleClose} style={{ color: '#f54b4b' }}>
						Etusivu
					</MenuItem>
				</Link>
				<Link to='/vaaliteemat'>
					<MenuItem onClick={handleClose} style={{ color: '#f54b4b' }}>
						Vaaliteemat
					</MenuItem>
				</Link>
				<Link to='/kirjoituksia'>
					<MenuItem onClick={handleClose} style={{ color: '#f54b4b' }}>
						Kirjoituksia
					</MenuItem>
				</Link>
				<Link to='/kukaolen'>
					<MenuItem onClick={handleClose} style={{ color: '#f54b4b' }}>
						Kuka olen?
					</MenuItem>
				</Link>
			</Menu>
		</div>
	)
}

export default NavMenu
