import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Logo from '../images/SDP_Logo_Punainen.png'
import NavMenu from './NavMenu'
import { Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},

	title: {
		flexGrow: 1,
		color: '#f54b4b',
	},
	logo: {
		maxWidth: 80,
		paddingBottom: '15px',
	},
	text: {
		color: '#be0a37',
	},
	menuButton: {
		flexGrow: 1,
		//position: 'relative',
		justifyContent: 'flex-end',
		marginLeft: 0,
		width: '100%',
		display: 'flex',
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
	navLinks: {
		flexGrow: 1,
		display: 'none',
		justifyContent: 'flex-end',
		[theme.breakpoints.up('sm')]: {
			display: 'flex',
		},
	},
	navTitle: {
		color: '#f54b4b',
		paddingRight: '15px',
	},
}))

const Navbar = () => {
	const classes = useStyles()

	return (
		<div>
			<AppBar position='static' color='transparent' elevation={0}>
				<Toolbar>
					<Link to='/'>
						<img src={Logo} alt='logo' className={classes.logo} />
					</Link>
					<Link to='/'>
						<Typography variant='h5' className={classes.title}>
							Eila Heinonen
						</Typography>
					</Link>
					<div className={classes.navLinks}>
						<Link to='/'>
							<Typography variant='h5' className={classes.navTitle}>
								Etusivu
							</Typography>
						</Link>
						<Link to='/vaaliteemat'>
							<Typography variant='h5' className={classes.navTitle}>
								Vaaliteemat
							</Typography>
						</Link>
						<Link to='/kirjoituksia'>
							<Typography variant='h5' className={classes.navTitle}>
								Kirjoituksia
							</Typography>
						</Link>
						<Link to='/kukaolen'>
							<Typography variant='h5' className={classes.navTitle}>
								Kuka olen?
							</Typography>
						</Link>
					</div>
					<div className={classes.menuButton}>
						<NavMenu />
					</div>
				</Toolbar>
			</AppBar>
		</div>
	)
}

export default Navbar
