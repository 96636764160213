import React from 'react'
import Head from './Head'
import Game from './Game'

const Home = () => {
	return (
		<>
			<Head />
			<Game />
		</>
	)
}

export default Home
