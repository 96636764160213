import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import Hahmokuvitus from '../images/SDP-hahmokuvitus-PIENI.png'
import Heart from '../images/SDP_Sydan_2020_Puhekupla.png'
import Heppa from '../game-images/heppa.jpeg'

const Vaaliteemat = () => {
	const styles = {
		redbox: {
			backgroundColor: '#f54b4b',
			minHeight: '400px',
			paddingLeft: '10%',
			paddingRight: '10%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			color: '#FFF',
		},
		imgBox: {
			backgroundImage: `url(${Hahmokuvitus})`,
			backgroundSize: 'auto',
			backgroundPosition: 'center',
			minHeight: '400px',
		},
		susiBox: {
			backgroundImage: `url(${Heppa})`,
			backgroundSize: 'cover',
			backgroundPosition: 'top',
			minHeight: '500px',
		},
		pinkbox: {
			backgroundColor: '#f5c8c3',
			paddingLeft: '10%',
			paddingRight: '10%',
			minHeight: '150px',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			color: '#be0a37',
			paddingTop: '20px',
			paddingBottom: '20px',
		},
		heart: {
			backgroundImage: `url(${Heart})`,
			backgroundSize: 'contain',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			minHeight: '200px',
		},
		textbox: {
			paddingLeft: '10%',
			paddingRight: '10%',
			paddingTop: '40px',
			paddingBottom: '40px',
			color: '#28282d',
		},
		redTextbox: {
			paddingLeft: '10%',
			paddingRight: '10%',
			paddingTop: '40px',
			paddingBottom: '40px',
			backgroundColor: '#be0a37',
			color: '#fff',
		},
	}
	return (
		<>
			<Grid container style={{ width: 'calc(100% + 16px)' }}>
				<Grid item xs={12} md={6} style={styles.redbox}>
					<Typography variant='h2'>Vaaliteemat</Typography>
				</Grid>
				<Grid item xs={12} md={6} style={styles.susiBox}></Grid>
				<Grid item xs={12} style={styles.pinkbox}>
					<Typography variant='h2'>Hyvinvointi arjessa kuuluu kaikille</Typography>
				</Grid>
				<Grid item xs={12} md={6} style={styles.textbox}>
					<Typography variant='body1'>
						Ihmisen hyvinvointi koostuu monista pienistä arjen murusista. Terveyden ja hyvinvoinnin edistäminen ja ongelmien ennaltaehkäisy ovat kaiken perusta. Tätä työtä tekevät yhteistyössä monet toimijat. Hyvän ja monipuolisen palveluketjun turvaamiseksi tarvitaan yhdessä tekemistä
						ja sopimista mm. kuntien ja hyvinvointialueen, järjestöjen, kuin muidenkin toimijoiden kesken. Tärkeää on, että asukkaiden hyvinvointia edistetään laajasti ja huomioidaan ennaltaehkäisevien ja korjaavien palveluiden tuottamisessa myös monipuolisen yhteistyön merkitys.
						<br></br>
						<br></br>
						Hyvinvointia tukee myös tieto ja kokemus siitä, että tarvittaessa on yhdenvertaisesti saatavilla tarpeenmukaiset ja saavutettavat sosiaali- ja terveyspalvelut eri elämäntilanteen mukaisesti. Palveluiden tulee löytyä lähellä ihmistä ja helposti. Palveluverkostosta päätettäessä
						tulee huomioida asukkaiden tarpeet ja palvelujen hyvä saavutettavuus.
						{/* Ihmisen hyvinvointi koostuu monista pienistä murusista. On tärkeää, että kaupunki pystyy
						jatkossakin tuottamaan ihmisen elinkaarta tukevat ja perusoikeuksiin kuuluvat peruspalvelut sekä
						toiminnot. Sosiaali- ja terveyspalvelujen järjestämis- ja tuottamisvastuu on Siun sotella.
						Joensuun kaupunki huolehtii edelleen asukkaittensa hyvinvoinnin ja terveyden edistämisestä. */}
						{/* <br></br>
						<br></br>
						<span style={{ color: '#be0a37', fontWeight: 800 }}>Monipuolisista palveluista</span> ja niiden hyvästä laadusta huolehtiminen on vastuunkantoa yhteisvastuullisessa yhteiskunnassa. Yhteiskunnassa, jossa kuntien tuottamilla terveys-ja hyvinvointipalveluilla on kansalaisten
						keskuudessa vahva tuki. <br></br> */}
					</Typography>
				</Grid>
				<Grid item xs={12} md={6} style={styles.textbox}>
					<Typography variant='body1'>
						{/* <span style={{ color: '#be0a37', fontWeight: 800 }}>Meillä kaikilla,</span> myös vammaisilla, on iästä riippumatta paljon erilaisia voimavaroja, jotka voimme antaa yhteiseen käyttöön. Siksi kaikissa kaupungin toiminnoissa, suunnittelusta päätöksentekoon ja toteutukseen,
						tuleekin turvata kaupunkilaisille esteetön elin- ja toimintaympäristö, joka mahdollistaa kaikkien omien voimavarojen hyödyntämisen sekä itsenäisen toimimisen. <br></br>
						<br></br> */}
						<span style={{ color: '#be0a37', fontWeight: 800 }}>Kulttuuri sekä liikunta</span> ovat tärkeitä mielihyvän ja hyvinvoinnin lähteitä. Ne ovat keinoja saada positiivisia elämyksiä, työllistyä, viettää mielekästä vapaa-aikaa ja kehittyä yksilönä sekä yhteisön jäsenenä. Kaikkien
						ikäryhmien ja erityisesti lasten ja nuorten matalan kynnyksen toimimis- ja osallistumismahdollisuuksiin tulee kiinnittää huomiota.
						<br></br>
						<br></br>
						<span style={{ color: '#be0a37', fontWeight: 800 }}>Meillä kaikilla,</span> myös vammaisilla, on iästä riippumatta paljon erilaisia voimavaroja, jotka voimme antaa yhteiseen käyttöön. Siksi kaikissa hyvinvointialueen sekä kuntien toiminnoissa, suunnittelusta päätöksentekoon
						ja toteutukseen, tuleekin turvata asukkaille esteetön elin- ja toimintaympäristö, joka mahdollistaa osallisuuden, omien voimavarojen hyödyntämisen sekä itsenäisen toimimisen. Toteutuessaan oikea-aikaiset ja monipuoliset kuntoutuspalvelut takaavat yhdenvertaisuuden
						toteutumisen.
						{/* <span style={{ color: '#be0a37', fontWeight: 800 }}>Kulttuuri sekä liikunta </span>ovat tärkeitä mielihyvän lähteitä. Ne ovat keinoja saada positiivisia elämyksiä, työllistyä, viettää mielekästä vapaa-aikaa ja kehittyä yksilönä sekä yhteisön jäsenenä. Kaikkien ikäryhmien ja
						erityisesti lasten ja nuorten matalan kynnyksen toimimis- ja osallistumismahdollisuuksiin tulee kiinnittää huomiota sekä huomioida ajan tuomat muuttuvat haasteet. */}
					</Typography>
				</Grid>

				<Grid item xs={12} style={styles.pinkbox}>
					<Typography variant='h2'>Yhteistyöllä ja osallisuutta vahvistaen tulevaan</Typography>
				</Grid>
				<Grid item xs={12} md={6} style={styles.heart}></Grid>
				<Grid item xs={12} md={6} style={styles.redTextbox}>
					<Typography variant='body1'>
						Suuri mahdollisuutemme on eri järjestöjen, kansalais- ja vapaaehtoistoimijoiden kanssa tehtävä yhteistyö. Yhteistyön kautta saamme laaja-alaisesti asiantuntijuuden ja osaamisen, kokemuksen ja tiedon, ammattilaisten ja päättäjien käyttöön ja palvelujen ja toimintojen
						kehittämistyöhön.
						{/* Suuri mahdollisuus on myös erilaisten järjestöjen, kansalais- ja vapaaehtoistoimijoiden kanssa tehtävä yhteistyö ja sitä kautta monenlaisen asiantuntijuuden ja osaamisen jakaminen. Järjestöissä toiminta tapahtuu vapaaehtoisvoimin lähellä ihmisen arkea ja edistää samalla
						vahvasti terveyttä ja hyvinvointia sekä lisää kaupungin elinvoimaisuutta. Meidän tulee nähdä tämän toiminnan sosiaalinen sekä taloudellinen merkittävyys monella tasolla ja taholla. */}
					</Typography>
				</Grid>
				<Grid item xs={12} style={styles.pinkbox}>
					<Typography variant='h2'>Elinvoimaa, hyviä palveluja ja hyvinvointia</Typography>
				</Grid>
				<Grid item xs={12} md={6} style={styles.textbox}>
					<Typography>
						<span style={{ color: '#be0a37', fontWeight: 800 }}>Pohjois-Karjalan maakunnan veto- ja elinvoimaisuuden vahvistuminen on maakuntalaisten yhteinen etu.</span> Elinvoimaisuus muodostuu monista asioista. Vahvaa elinvoimaisuutta tukevat hyvin toimivat terveyttä ja hyvinvointia
						edistävät palvelut, sosiaali- ja terveyspalvelut, monipuolinen järjestö- ja yritystoiminta sekä asukkaiden osallisuuden mahdollisuudet. Toimiva ihmisen arki. Elinvoimaisuus edellyttää myös työtä yritysten ja toimijoiden sijoittumiseksi tänne sekä hyviä koulutus- ja
						opiskelumahdollisuuksia. Nämä toimet osaltaan tukevat myös rekrytoitaessa hyvinvointialueen työntekijöitä alueelle. Työntekijöiden työhyvinvointia ja työolosuhteita ei myöskään saa unohtaa vaan näitä tekijöitä tulee yhteisvoimin kehittää.
						{/* <span style={{ color: '#be0a37', fontWeight: 800 }}>Meidän kaikkien etu on Joensuun, koko kaupunkiseudun ja maakunnan veto- ja elinvoimaisuuden vahvistuminen.</span> Elinvoimaisuus muodostuu monista asioista ja sen edistäminen edellyttää yhteistä näkemysta ja työtä. Tämän
						toteutumisen yksi edellytys on toimivat lento- ja raideyhteydet sekä tie- ja tietoliikenneyhteydet. <br></br>
						<br></br>Työllisyyden kasvu edellyttää työtä ja yritysten ja toimijoiden sijoittumista tänne. Mahdollistavan toimintaympäristön lisäksi on varmistettava osaava työvoiman saanti turvaamalla jatkossakin monipuoliset ja laadukkaat koulutus- ja opiskelumahdollisuudet. Tärkeää
						vetovoimaisuutta lisäävät myös elinvoimaa vahvistavien hyvinvointia ja terveyttä edistävien palvelujen saatavuus ja monipuolisuus. */}
					</Typography>
				</Grid>
				<Grid item xs={12} md={6} style={styles.imgBox}></Grid>
			</Grid>
		</>
	)
}

export default Vaaliteemat
